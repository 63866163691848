import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UILayout, UIPagination, UIBlock } from 'bora-material-ui'
import { isEmpty, pathOr, prop } from 'ramda'
import { PageTitle, NotFoundContent } from '../Common'
import NewsItem from './NewsItem'
import { getNewsItems } from '../../../services/sales-static/api'
import { mapStateToPropsForStaticPages } from '../../../services/sales-static/utils'
import messages from '../../../../src/consts/messages'
import { THEME } from 'bora-material-ui/themes'

const styles = {
  newsFlexEnd: {
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
  },
  paginationStyles: {
    padding: '32px 0 0 0',
    xs: { 'j-flex-center': true, padding: '24px 0 0 0', hideStepButtons: true },
  },
}

const xsStyles = {
  news: {
    margin: '10px',
    width: 'auto',
    style: { 'align-self': 'center', margin: window.brandProps.theme === THEME.SAAREMAA ? '0' : 'inherit' },
  },
}

const tabletStyle = { width: '55%' }

class NewsContent extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      news: null,
      selectedPage: 1,
      pageCount: 1,
    }
  }

  componentDidMount() {
    this.fetchNewsForPage(this.state.selectedPage)
  }

  fetchNewsForPage = (selectedPage) => {
    getNewsItems(this.props.locale, selectedPage)
      .then(({ items, pageCount }) => this.setState({ news: items, pageCount, selectedPage }))
      .catch(() => this.setState({ news: [] }))
  }

  styleBorderExceptLast = {
    borderBottom: 'solid 1px #b0bec5',
  }

  generateNewsItems = (news, muiTheme) =>
    news.map((item, i) => (
      <NewsItem
        key={prop('id', item)}
        date={prop('published_at', item)}
        title={prop('subject', item)}
        description={prop('body', item)}
        imagePath={pathOr('', ['image', 'url'], item)}
        style={i < news.length - 1 ? this.styleBorderExceptLast : {}}
        muiTheme={muiTheme}
      />
    ))

  render() {
    const { intl, muiTheme } = this.context
    return (
      <UILayout
        data-testid="news-content"
        width="680px"
        margin="50px 20px 20px 60px"
        style={{
          ...styles.newsFlexEnd,
          ...(muiTheme.ids.newsContent && {
            ...muiTheme.ids.newsContent,
          }),
        }}
        smm={xsStyles.news}
        display-if={this.state.news}
        pre-ms={tabletStyle}
      >
        <UIBlock bgColor="#ffffff" padding="40px 26px 10px 26px" border="none" borderRadius="4px">
          {isEmpty(this.state.news) ? (
            <NotFoundContent />
          ) : (
            <UILayout column>
              <PageTitle title={intl.formatMessage(messages.newsPageTitle)} />
              <UILayout column>
                {this.state.news ? (
                  this.generateNewsItems(this.state.news, muiTheme)
                ) : (
                  <UILayout j-flex-center margin="60px 0">
                    <CircularProgress />
                  </UILayout>
                )}
              </UILayout>
              <UIPagination
                display-if={!this.props.hidePagination && this.state.pageCount > 1}
                paginationStyle={styles.paginationStyles}
                prevText={intl.formatMessage(messages.previousPagination)}
                nextText={intl.formatMessage(messages.nextPagination)}
                onSelectPage={this.fetchNewsForPage}
                selectedPage={this.state.selectedPage}
                pageCount={Number(this.state.pageCount)}
              />
            </UILayout>
          )}
        </UIBlock>
      </UILayout>
    )
  }
}

NewsContent.propTypes = {
  locale: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
  hidePagination: PropTypes.bool,
}

NewsContent.defaultProps = {
  pageTitle: '',
  hidePagination: false,
}

NewsContent.contextTypes = {
  intl: PropTypes.object,
  muiTheme: PropTypes.object,
}

export default connect(mapStateToPropsForStaticPages)(NewsContent)
