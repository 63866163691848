import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loadable from 'react-loadable'
import { bindActionCreators } from 'redux'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import { values, pathOr, has } from 'ramda'
import s from './Profile.module.scss'

import messages from '../../../../consts/messages'

import { goToPage } from '../../../../actions'

import { actions, selectors } from '../../provider'

import UIElements from '../../../../consts/UIElements'

const stylesXs = {
  profileInner: { 'j-flex-center': true },
}

const ProfileTickets = Loadable({
  loader: () => import('../ProfileTickets/ProfileTickets.js'),
  loading: () => null,
})

const ProfileSettings = Loadable({
  loader: () => import('../ProfileSettings/ProfileSettings.js'),
  loading: () => null,
})

const ProfileUsers = Loadable({
  loader: () => import('../ProfileUsers'),
  loading: () => null,
})

const ProfileMenuItem = ({ onClick, title, isOpened, name }, { muiTheme }) => {
  const idToAdd = isOpened ? muiTheme.ids.ProfileMenuTextActive : muiTheme.ids.ProfileMenuText

  return (
    <UILayout
      onClick={() => onClick(name)}
      cursor="pointer"
      position="relative"
      maxWidth="100%"
      margin="0 84px 0 0"
      data-testid={`profile-tab-${name}`}
      {...muiTheme.ids.ProfileMenuItem}
    >
      <UIText translate={title} {...idToAdd} />

      <div className={s.iconHolder} display-if={isOpened}>
        <UIIcon type="iconSelectedTab" fill="#fafafa" />
      </div>
    </UILayout>
  )
}

ProfileMenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
  isOpened: PropTypes.bool,
  name: PropTypes.any.isRequired,
}

ProfileMenuItem.defaultProps = {
  isOpened: true,
}

ProfileMenuItem.contextTypes = {
  muiTheme: PropTypes.object,
}

const profileMenuItems = [
  { name: 'tickets', title: messages.tickets },
  { name: 'settings', title: messages.yourSettings },
  { name: 'users', title: messages.users },
]

class Profile extends React.Component {
  constructor(props) {
    super()
    const { match } = props
    const { tabName } = match.params
    this.state = {
      openedTab: tabName,
    }
  }

  componentDidMount() {
    const { selectedCompany, isLoggedIn } = this.props

    this.props.fetchAllSailPackages()

    if (isLoggedIn) {
      this.props.fetchUserCustomerInfo()

      if (this.state.openedTab === 'users' && selectedCompany && selectedCompany.companyId) {
        this.props.selectUsersCompany(selectedCompany.companyId)
      }
    }
  }

  componentWillReceiveProps() {
    const { hash } = window.location
    const hashArray = hash.split('/')
    const [, , , openedTab] = hashArray

    if (openedTab !== this.state.openedTab) {
      this.setState({ openedTab })
    }
  }

  switchTab(tab) {
    this.setState({ openedTab: tab }, () => {
      const { hash } = window.location
      const hashArray = hash.split('/')
      const cuttedHashArray = hashArray.slice(0, 3)
      const cuttedHash = cuttedHashArray.join('/')
      window.location.replace(`/${cuttedHash}/${tab}`)
    })
  }

  render() {
    const { muiTheme } = this.context
    const { userDetails } = this.props

    const companiesArray = values(this.props.companies) || []

    const isThereCreditCompany = companiesArray.filter((item) => has('monthlyCreditLimitBalance')(item)).length > 0

    return (
      <div className={s.root}>
        <UILayout data-testid="profile-menu" center j-flex-center {...muiTheme.ids.profileMenu}>
          <UILayout flex-end width={UIElements.CONTENT_WIDTH} maxWidth="100%" xs={stylesXs.profileInner}>
            {profileMenuItems
              .filter(({ name }) => (name === 'users' ? userDetails.isManager && isThereCreditCompany : true))
              .map((item) => (
                <ProfileMenuItem
                  name={item.name}
                  key={item.name}
                  title={item.title}
                  onClick={(tab) => this.switchTab(tab)}
                  isOpened={this.state.openedTab === item.name}
                />
              ))}
          </UILayout>
        </UILayout>

        <ProfileTickets display-if={this.state.openedTab === 'tickets'} />
        <ProfileSettings display-if={this.state.openedTab === 'settings'} valid={true} />
        <ProfileUsers display-if={this.state.openedTab === 'users' && userDetails.isManager} />
      </div>
    )
  }
}

Profile.propTypes = {
  goToPage: PropTypes.func.isRequired,
  userDetails: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  selectedCompany: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  companies: PropTypes.object.isRequired,
}

Profile.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

export default connect(
  (state) => ({
    isLoggedIn: selectors.isLoggedInSelector(state),
    userDetails: selectors.userDetailsSelector(state),
    selectedCompany: selectors.getManagersSelectedCompany(state),
    companies: pathOr({}, ['user', 'companies'])(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchAllSailPackages: actions.fetchAllSailPackages,
        selectUsersCompany: actions.selectUsersCompany,
        fetchUserCustomerInfo: (props) => actions.fetchUserCustomerInfo(props),
        goToPage,
      },
      dispatch
    )
)(Profile)
