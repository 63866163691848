import React, { Fragment, PureComponent } from 'react'
import styled from 'styled-components'
import { any, contains, equals, not, propEq, pathOr } from 'ramda'
import moment from 'moment'

import { UIText } from 'bora-material-ui'
import { EditablePanel, EmptyColumn, Panel, Row } from '../../../components'
import { CustomerDetails } from '../CustomerDetails'
import { DifferenceToPay } from '../DifferenceToPay'
import { PaymentInfo } from '../PaymentInfo'
import { ReservationItems } from '../ReservationItems'
import { SailReference } from '../SailReference'
import { VehicleDetails } from '../VehicleDetails'
import { CustomWeightTip } from '../../../modules/Booking/containers/MainInside/Steps/ConfirmPay/ConfirmationInfo/TripTicket'
import { FinishButton, LegacyTicketHeader, LegacyTicketFooter } from '../legacy'

import messages from '../../../consts/messages'
import { translate } from '../../Common/Translator'

import { ITEM_TYPE_VEHICLE, VEHICLE_TYPE_TRAILER } from '../../../consts/reservation'

import { PassengerDetails } from '../PassengerDetails'
import { isLaaksaareSail } from '../../../services/reservation/selectors.util'
import { expiredFromSail, isStatusOfSailCancelled } from '../../Booking/containers/PaymentResults/PaymentResults'
import { LoopCircleLoading } from 'react-loadingg'
import { getStyle, isLiinilaevad } from '../../../utils/liinilaevadThemeUtils'

export const shouldEnableWChangeResEdit = () => window.brandProps && window.brandProps.enableWeightChangeOnResEdit

const ResponsiveDivForButton = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  @media (max-width: 840px) {
    flex-flow: column;
    max-width: ${getStyle(undefined, '250px')};
  }
`

const MainButtonWrap = styled.div`
  display: flex;
  align-items: flex-end;
  @media (max-width: 840px) {
    margin-top: ${getStyle('25px')};
    display: flex;
    align-items: center;
    justify-content: ${getStyle('center', 'flex-start')};
    & > div {
      width: ${getStyle('280px')} !important;
    }
  }
`

const Wrapper = styled.div`
  font-family: Roboto, sans-serif;
`
const spinnerPosition = {
  top: '100px',
  right: '45%',
}

const isRingsuSail = (arr) => {
  let result = false
  arr.forEach((elem) => {
    if (contains('RIN-', elem.code) || contains('-RIN', elem.code)) {
      result = true
    }
  })
  return result
}

export const getSailEditAllowance = pathOr('', ['sailRefs', [0], 'allowance', 'edit'])

const isRefundAmountZero = ({ toRefundAmount, paidAmount: oldTotal, totalPrice: { amount: newTotal } }) =>
  oldTotal > newTotal && !toRefundAmount

class EditTicketView extends PureComponent {
  static defaultProps = {
    currency: 'EUR',
    sailPackages: [],
    customWeightMap: {},
    replacementLegsAvailable: false,
    editStartedByUrlParam: false,
  }

  componentDidMount() {
    const {
      fetchReservation,
      match: { params },
      reservationToken,
      editStartedByUrlParam = false,
    } = this.props

    if (params.token) {
      if (editStartedByUrlParam) {
        setTimeout(() => fetchReservation(Number(params.token)), 1000)
        return
      }

      fetchReservation(Number(params.token))
      return
    }

    if (reservationToken) {
      fetchReservation(reservationToken)
    }
  }

  getTicketType = (items, seqN) => {
    let type = ''
    items.forEach((item) => {
      if (item.ownerSeqNs.indexOf(seqN) > -1) {
        type = item.priceCategoryTranslation
      }
    })
    return type
  }

  guestsPerSail = ({ guests = [], items = [] }, sailIndex) => {
    const guestsPerSail = []
    guests.forEach((guest) => {
      items.forEach((item) => {
        if (item.ownerSeqNs.indexOf(guest.seqN) !== -1 && item.sailPackageSeqN === sailIndex + 1) {
          guestsPerSail.push(guest)
        }
      })
    })
    return guestsPerSail
  }

  ticketAmountChanged = (reservation, sailIndex) => {
    if (!reservation || !reservation.modifiedReservation) {
      return false
    }
    const guestsPerSail = []
    reservation.items.forEach((item) => {
      if (item.sailPackageSeqN === sailIndex + 1) {
        guestsPerSail.push(...item.ownerSeqNs)
      }
    })

    const oldGuestsPerSail = []
    reservation.modifiedReservation.reservation.items.forEach((item) => {
      if (item.sailPackageSeqN === sailIndex + 1) {
        oldGuestsPerSail.push(...item.ownerSeqNs)
      }
    })

    return not(
      equals(
        guestsPerSail.sort((a, b) => a - b),
        oldGuestsPerSail.sort((a, b) => a - b)
      )
    )
  }

  renderVehicleInfo = (index, items = []) => {
    const item = items.find((item) => item.type === 'VEHICLE' && item.sailPackageSeqN === index + 1)
    return Boolean(item)
  }

  render() {
    const {
      currency,
      finishEditReservation,
      openCustomerModal,
      openSailItemsModal,
      openPassengerModal,
      openSailReferenceModal,
      reservation,
      sailPackages,
      customWeightMap,
      showModal,
      setModalData,
      setPassengerToEdit,
      editTicketSailPackage,
      editMode,
      replacementLegsAvailable,
      removeSingleGuest,
      outOfBookingBoundary,
      isFetching,
    } = this.props
    const { vehicles = [] } = reservation || {}

    const customWeightMapOrEmpty = customWeightMap
    let editHasNoErrors = false

    if (reservation && reservation.guests && reservation.guests.length !== 0) {
      let allGuestsCorrect = true
      reservation.guests.forEach((guest) => {
        if (
          propEq('firstName', undefined)(guest) ||
          propEq('lastName', undefined)(guest) ||
          propEq('citizenship', undefined)(guest) ||
          (propEq('personalIdentificationNumber', undefined)(guest) && propEq('birthday', undefined)(guest))
        ) {
          allGuestsCorrect = false
        }
      })

      editHasNoErrors = not(isRingsuSail(editTicketSailPackage)) || allGuestsCorrect
    }
    if (reservation && (!reservation.guests || reservation.guests.length === 0)) {
      editHasNoErrors = true
    }
    const isLaakSail = reservation ? isLaaksaareSail(reservation.sailPackages) && !outOfBookingBoundary : false

    if (!reservation) {
      return (
        <Fragment>
          <LoopCircleLoading color={window.brandProps.muiTheme.menuItem.color} style={spinnerPosition} />
        </Fragment>
      )
    }
    const chooseSailDate = pathOr('', ['sailRefs', [0], 'departureAt'])
    const isTalAegTal = sailPackages.every(({ code }) => code === 'TAL-AEG' || code === 'AEG-TAL')
    return (
      <Wrapper>
        <LegacyTicketHeader reservationId={reservation.reservationId} isLaak={isLaakSail} />

        <div>
          <Panel noBorder>
            <Panel row>
              <EditablePanel onEdit={openCustomerModal} locked={false} dataTestId="customer">
                <CustomerDetails reservation={reservation} />
              </EditablePanel>
            </Panel>

            <Panel row>
              {sailPackages.map((sailPackage, index) => {
                const showPencilTip = any(({ priceCategory = '' }) => customWeightMapOrEmpty[priceCategory])(
                  sailPackage.items || []
                )
                const { refundOfferId } = sailPackage
                if (refundOfferId) return null
                const expired = expiredFromSail(sailPackage)
                const isCancelled = isStatusOfSailCancelled(sailPackage)
                const isSailExpired = isCancelled ? expired : moment(chooseSailDate(sailPackage)).isBefore(moment())
                const isTicketDataChanged = !editMode ? this.ticketAmountChanged(reservation, index) : false
                const allowEditSail = getSailEditAllowance(sailPackage)
                return (
                  <Panel>
                    <EditablePanel
                      dataTestId={`route-leg-${index + 1}`}
                      newLineEdit
                      onEdit={() => openSailReferenceModal(index)}
                      locked={isSailExpired || !allowEditSail}
                    >
                      <SailReference idx={index + 1} sailPackage={sailPackage} locked={!replacementLegsAvailable} />
                    </EditablePanel>
                    {reservation &&
                      reservation.vehicles &&
                      !reservation.vehicles.some((vehicle) => vehicle.type === 'BICYCLE') &&
                      this.renderVehicleInfo(index, reservation.items) && (
                        <Panel row noWrap>
                          {this.renderCarPanel(index)}
                          {this.renderTrailerPanel(index)}
                        </Panel>
                      )}

                    <Panel row={sailPackages.length < 2} dataTestId={`ticket-info-leg-${index + 1}`}>
                      <EditablePanel
                        dataTestId={`tickets-leg-${index + 1}`}
                        noPadding
                        onEdit={() => openSailItemsModal(index)}
                        locked={isSailExpired || !allowEditSail}
                      >
                        <ReservationItems
                          currency={currency}
                          items={sailPackage.items.filter(({ internal }) => !internal)}
                          customWeightMap={customWeightMapOrEmpty}
                          vehicles={vehicles}
                          showModal={showModal}
                          setModalData={setModalData}
                          hideZeroPrice={isLaakSail}
                          whileEdit
                          lockAllEditPanels={false}
                        />
                        <CustomWeightTip shouldShow={showPencilTip} />
                      </EditablePanel>
                      {isRingsuSail(editTicketSailPackage) && reservation.guests && (
                        <Panel>
                          {this.guestsPerSail(reservation, index).map((guest) => (
                            <Panel row noBorder>
                              <EditablePanel
                                onEdit={() => {
                                  setPassengerToEdit(guest.seqN)
                                  openPassengerModal()
                                }}
                                showRemoveButton
                                onRemove={() =>
                                  removeSingleGuest({
                                    guestSeqN: guest.seqN,
                                    sailSeqNum: index,
                                    reservationToken: reservation.token,
                                  })
                                }
                                locked={isSailExpired}
                              >
                                <PassengerDetails
                                  ticketType={this.getTicketType(reservation.items, guest.seqN)}
                                  personalCode={guest.personalIdentificationNumber}
                                  firstName={guest.firstName}
                                  lastName={guest.lastName}
                                  seqN={guest.seqN}
                                  birthday={guest.birthday}
                                  citizenship={guest.citizenship}
                                />
                              </EditablePanel>
                            </Panel>
                          ))}
                          <div
                            style={{
                              color: '#d0021b',
                              paddingTop: '30px',
                              display: isTicketDataChanged ? 'block' : 'none',
                            }}
                          >
                            {translate(messages.ticketDataChanged)}
                          </div>
                        </Panel>
                      )}
                    </Panel>
                  </Panel>
                )
              })}
            </Panel>

            <Panel display-if={false}>
              <Row>
                <EmptyColumn />
                <PaymentInfo paymentInfo={reservation.paymentInfo} />
              </Row>
            </Panel>

            <Panel>
              <Row>
                <ResponsiveDivForButton>
                  {!isLaakSail && (
                    <div>
                      <DifferenceToPay paymentInfo={reservation.paymentInfo} />
                      {!isLiinilaevad && (
                        <UIText
                          display-if={isTalAegTal && isRefundAmountZero(reservation.paymentInfo)}
                          align="left"
                          color="#d0021b"
                          size="12px"
                          lineHeight="1.71"
                          translate={messages.amountRefundTalAegTal}
                        />
                      )}
                    </div>
                  )}
                  <MainButtonWrap>
                    <FinishButton
                      differenceToPay={this.getDifferenceToPay(isLaakSail)}
                      reservation={reservation}
                      finishEditReservation={finishEditReservation}
                      disabled={!editHasNoErrors || isFetching}
                    />
                  </MainButtonWrap>
                </ResponsiveDivForButton>
              </Row>
            </Panel>
          </Panel>
        </div>

        <LegacyTicketFooter />
      </Wrapper>
    )
  }

  getDifferenceToPay(isLaak = false) {
    if (isLaak) return 0
    const { reservation } = this.props

    if (!reservation) {
      return 0
    }

    const {
      paymentInfo: { toPayAmount = 0, toRefundAmount = 0 },
    } = reservation

    return toPayAmount - toRefundAmount
  }

  renderCarPanel = (index) => {
    const {
      // openCarModal,
      reservation,
    } = this.props

    if (!(reservation && Array.isArray(reservation.vehicles))) {
      return null
    }

    // const car: ?Vehicle = reservation.vehicles.find((vehicle) => vehicle.type === VEHICLE_TYPE_CAR)
    let car = null
    reservation.vehicles.forEach((vehicle) => {
      const item = reservation.items.find(
        (item) =>
          vehicle.seqN === item.ownerSeqNs[0] &&
          item.priceCategorySubType === ITEM_TYPE_VEHICLE &&
          item.sailPackageSeqN === index + 1
      )
      if (item) {
        car = vehicle
      }
    })
    if (!car) {
      return null
    }

    return (
      <Panel row>
        <VehicleDetails vehicle={car} />
      </Panel>
    )
  }

  renderTrailerPanel = (index) => {
    const {
      // openTrailerModal,
      reservation,
    } = this.props

    if (!(reservation && Array.isArray(reservation.vehicles))) {
      return null
    }

    // const trailer: ?Vehicle = reservation.vehicles.find((vehicle) => vehicle.type === 'LONG TRAILER')
    let trailer = null
    reservation.vehicles.forEach((vehicle) => {
      const item = reservation.items.find(
        (item) =>
          vehicle.seqN === item.ownerSeqNs[0] &&
          item.priceCategorySubType === VEHICLE_TYPE_TRAILER &&
          item.sailPackageSeqN === index + 1
      )
      if (item) {
        trailer = vehicle
      }
    })

    if (!trailer) {
      return null
    }

    return (
      <Panel row>
        <VehicleDetails vehicle={trailer} />
      </Panel>
    )
  }
}

export { EditTicketView }
