import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIDropDownMenu, UIText } from 'bora-material-ui'
import { propOr, propEq, findIndex, nth } from 'ramda'
import messages from '../../../consts/messages'
import { translate } from '../../Common/Translator'

import InputField from '../../../formComponents/InputField'
import { phoneCode, requiredNumber } from '../../../utils/validate'
import { RequiredLabel } from '../../../formComponents/uiComponents'
import { olderBrowser } from '../../../utils/browserUtils'

const styles = {
  dropDownStyles: {
    width: '500px',
    maxWidth: '100%',
    margin: '0',
    padding: '0',
  },
  wrapperLabelStyles: {
    width: 'auto',
    center: true,
    display: 'flex',
    align: 'right',
    padding: '0 20px 0 0',
    position: 'relative',
    sm: { padding: '0 0 6px 10px' },
  },
  labelStyles: {
    width: '180px',
    size: '15px',
    fontWeight: 'normal',
    color: '#94a8b2',
    weight: 'normal',
    align: 'right',
    sm: { align: 'left', width: 'auto', margin: '0 8px 0 0' },
  },
  errorStyles: {
    color:
      window.brandProps.muiTheme.palette && window.brandProps.muiTheme.palette.error
        ? window.brandProps.muiTheme.palette.error
        : '#f44336',
    font: 'GinesoNormDem, Fjalla One',
    marginTop: '4px',
    fontSize: '12px',
  },
  starStyles: {
    position: 'absolute',
    top: '6px',
    right: '8px',
    size: '14px',
    fontStyle: 'italic',
    color: '#d0021b',
    sm: { position: 'static' },
  },
  wrapperInputStyles: {
    height: 'auto',
    bgColor: 'transparent',
    boxShadow: '0',
    padding: '0',
    position: 'static',
    margin: '0 10px 0 0',
    plusColor: 'black',
  },
  wrapperStyles: { position: 'relative', padding: '0', column: false, sm: { column: 'true' } },
  inputStyle: { background: 'transparent', padding: '1px 14px 0px 10px' },
}

const xsStylesWrap = (isDisabled) => ({ margin: isDisabled ? '0 0 20px' : '0 0 8px 0' })

const mainStyle = (isHiddenOrDisabled) => {
  const otherStyles = isHiddenOrDisabled
    ? { boxShadow: 'none', height: 'auto', backgroundColor: 'transparent' }
    : {
        boxShadow: 'rgba(0, 0, 0, 0.04) 0px 11px 6px 0px, rgba(0, 0, 0, 0.02) 0px 6px 4px 0px',
        height: '44px',
        backgroundColor: 'white',
      }

  return {
    ...otherStyles,
    maxWidth: '500px',
    borderRadius: '1px',
    sm: { maxWidth: 'none' },
  }
}

const CompanyItemTemplate = (props) => {
  if (!props) return null
  return (
    <UILayout height="44px" align="center">
      <UIText size="15px" color="black" text={props.title || props.value.title} />
    </UILayout>
  )
}

CompanyItemTemplate.propTypes = {
  value: PropTypes.object,
  title: PropTypes.string,
}

CompanyItemTemplate.defaultProps = {
  value: {},
  title: '',
}

const LabelValue = (
  {
    label,
    text,
    fieldValue,
    isDisabled,
    required,
    type,
    validate,
    allowedCountries,
    onCountrySelect,
    translateLabel,
    ...props
  },
  context
) => {
  const { intl, muiTheme } = context

  const isHiddenOrDisabled = type === 'hidden' || isDisabled

  const labelStyles = { ...styles.labelStyles, ...props.labelStyles }

  if (label === 'country') {
    const getCountryIndexByValue = findIndex(propEq('code', fieldValue))
    const countryIndex = getCountryIndexByValue(allowedCountries)

    let thisCountryName = ''

    if (countryIndex >= 0) {
      const currentCountry = nth(countryIndex)(allowedCountries)
      thisCountryName = propOr('', 'title')(currentCountry)
    }
    return (
      <UILayout margin="0 0 20px" center sm={{ ...xsStylesWrap(isDisabled), 'flex-start': true, column: true }}>
        <UILayout {...styles.wrapperLabelStyles}>
          <UIText {...labelStyles}>{intl.formatMessage(messages.country)}</UIText>
          {!isDisabled && <span className="star">*</span>}
        </UILayout>
        {!isDisabled && (
          <UILayout width="500px">
            <UIDropDownMenu
              itemsArr={allowedCountries}
              itemTemplate={CompanyItemTemplate}
              selectionRenderer={CompanyItemTemplate}
              initValue={countryIndex}
              select={(value) => {
                onCountrySelect(value.code)
              }}
              styles={styles.dropDownStyles}
            />
          </UILayout>
        )}
        {isDisabled && (
          <UILayout data-testid="value-profile-country-static">
            <UIText font={muiTheme.fontFamily} color="#000" padding="0 0 0 10px" size="18px" cursor="not-allowed">
              {thisCountryName}
            </UIText>
          </UILayout>
        )}
      </UILayout>
    )
  }

  if (label === 'phone') {
    const phoneWrapperLabelStyles = { ...styles.wrapperLabelStyles, padding: '0', sm: { padding: 0 } }
    const phoneWrapStylesXs = { ...xsStylesWrap(isDisabled), column: true, 'flex-start': true }
    const phoneLabelStyles = { ...styles.wrapperLabelStyles, sm: { padding: 0 } }
    const phoneLabelStylesXs = { ...labelStyles.sm, padding: '0 0 3px 10px' }
    const phoneCodeStyles = { ...mainStyle(isHiddenOrDisabled), width: '60px' }
    const phoneCodeWrapperInputStyles = {
      ...styles.wrapperInputStyles,
      width: '70px',
      ...(olderBrowser && { width: '100px' }),
    }
    const phoneCodeWrapperStyles = { ...styles.wrapperStyles, width: 'auto' }
    const phoneNumberWrapperStyles = { ...styles.wrapperStyles, maxWidth: '413px' }
    const phoneCodeInputStyles = { ...styles.inputStyle }

    if (isHiddenOrDisabled) {
      phoneCodeStyles.width = '40px'
      phoneCodeInputStyles.padding = '0'
      phoneCodeWrapperInputStyles.margin = '0 0 0 10px'
      phoneWrapperLabelStyles.padding = '0'
    }

    return (
      <UILayout margin="0 0 20px" align="stretch" sm={phoneWrapStylesXs}>
        <RequiredLabel
          text={text || translate(messages.phone)}
          required={required}
          hideLabel={false}
          hideStar={false}
          invalid={false}
          wrapperLabelStyles={phoneLabelStyles}
          starStyles={styles.starStyles}
          labelStyles={{ ...labelStyles, sm: phoneLabelStylesXs }}
        />
        <UILayout>
          <InputField
            validate={[phoneCode]}
            height="auto"
            name="code"
            data-testid={`${isDisabled ? 'value-profile-phoneCode' : 'field-profile-phoneCode'}`}
            type={type}
            showPlus
            hideLabel
            hideStar
            hintText=""
            wrapperInputStyles={phoneCodeWrapperInputStyles}
            wrapperStyles={phoneCodeWrapperStyles}
            style={phoneCodeStyles}
            wrapperLabelStyles={phoneWrapperLabelStyles}
            disabled={isDisabled}
            required={required}
            errorStyles={styles.errorStyles}
            starStyles={styles.starStyles}
            inputStyle={phoneCodeInputStyles}
            translateLabel={translateLabel}
          />
          <InputField
            validate={[requiredNumber]}
            height="auto"
            name="phoneNumber"
            data-testid={`${isDisabled ? 'value-profile-phoneNumber' : 'field-profile-phoneNumber'}`}
            hideLabel
            hideStar
            type={type}
            hintText=""
            wrapperInputStyles={styles.wrapperInputStyles}
            wrapperStyles={phoneNumberWrapperStyles}
            style={mainStyle(isHiddenOrDisabled)}
            wrapperLabelStyles={phoneWrapperLabelStyles}
            labelStyles={labelStyles}
            disabled={isDisabled}
            required={required}
            errorStyles={styles.errorStyles}
            starStyles={styles.starStyles}
            inputStyle={phoneCodeInputStyles}
          />
        </UILayout>
      </UILayout>
    )
  }

  return (
    <UILayout
      margin="0 0 20px"
      data-testid={`${isDisabled ? `value-${props.id}` : `field-${props.id}`}`}
      flex-end
      sm={xsStylesWrap(isDisabled)}
    >
      <InputField
        validate={validate}
        height="auto"
        name={label}
        text={text}
        type={type}
        hintText=""
        wrapperInputStyles={styles.wrapperInputStyles}
        wrapperStyles={styles.wrapperStyles}
        style={mainStyle(isHiddenOrDisabled)}
        wrapperLabelStyles={styles.wrapperLabelStyles}
        labelStyles={labelStyles}
        disabled={isDisabled}
        required={required}
        errorStyles={styles.errorStyles}
        starStyles={styles.starStyles}
        value={fieldValue}
        inputStyle={styles.inputStyle}
        hideStar={isDisabled}
        translateLabel={translateLabel}
      />
    </UILayout>
  )
}

LabelValue.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

LabelValue.defaultProps = {
  required: false,
  type: 'text',
  labelStyles: {},
  text: '',
  validate: [],
}

export default LabelValue
